var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { NotificationProvider, useNotification as useRcNotification } from 'rc-notification';
import warning from '../_util/warning';
import { ConfigContext } from '../config-provider';
import { getCloseIcon, PureContent } from './PurePanel';
import useStyle from './style';
import { getMotion, getPlacementStyle } from './util';
const DEFAULT_OFFSET = 24;
const DEFAULT_DURATION = 4.5;
const DEFAULT_PLACEMENT = 'topRight';
const Wrapper = ({ children, prefixCls }) => {
    const [, hashId] = useStyle(prefixCls);
    return (React.createElement(NotificationProvider, { classNames: { list: hashId, notice: hashId } }, children));
};
const renderNotifications = (node, { prefixCls, key }) => (React.createElement(Wrapper, { prefixCls: prefixCls, key: key }, node));
const Holder = React.forwardRef((props, ref) => {
    const { top, bottom, prefixCls: staticPrefixCls, getContainer: staticGetContainer, maxCount, rtl, onAllRemoved, } = props;
    const { getPrefixCls, getPopupContainer, notification } = React.useContext(ConfigContext);
    const prefixCls = staticPrefixCls || getPrefixCls('notification');
    // =============================== Style ===============================
    const getStyle = (placement) => getPlacementStyle(placement, top !== null && top !== void 0 ? top : DEFAULT_OFFSET, bottom !== null && bottom !== void 0 ? bottom : DEFAULT_OFFSET);
    const getClassName = () => classNames({ [`${prefixCls}-rtl`]: rtl });
    // ============================== Motion ===============================
    const getNotificationMotion = () => getMotion(prefixCls);
    // ============================== Origin ===============================
    const [api, holder] = useRcNotification({
        prefixCls,
        style: getStyle,
        className: getClassName,
        motion: getNotificationMotion,
        closable: true,
        closeIcon: getCloseIcon(prefixCls),
        duration: DEFAULT_DURATION,
        getContainer: () => (staticGetContainer === null || staticGetContainer === void 0 ? void 0 : staticGetContainer()) || (getPopupContainer === null || getPopupContainer === void 0 ? void 0 : getPopupContainer()) || document.body,
        maxCount,
        onAllRemoved,
        renderNotifications,
    });
    // ================================ Ref ================================
    React.useImperativeHandle(ref, () => (Object.assign(Object.assign({}, api), { prefixCls,
        notification })));
    return holder;
});
// ==============================================================================
// ==                                   Hook                                   ==
// ==============================================================================
export function useInternalNotification(notificationConfig) {
    const holderRef = React.useRef(null);
    // ================================ API ================================
    const wrapAPI = React.useMemo(() => {
        // Wrap with notification content
        // >>> Open
        const open = (config) => {
            var _a;
            if (!holderRef.current) {
                warning(false, 'Notification', 'You are calling notice in render which will break in React 18 concurrent mode. Please trigger in effect instead.');
                return;
            }
            const { open: originOpen, prefixCls, notification } = holderRef.current;
            const noticePrefixCls = `${prefixCls}-notice`;
            const { message, description, icon, type, btn, className, style, role = 'alert', closeIcon } = config, restConfig = __rest(config, ["message", "description", "icon", "type", "btn", "className", "style", "role", "closeIcon"]);
            const realCloseIcon = getCloseIcon(noticePrefixCls, closeIcon);
            return originOpen(Object.assign(Object.assign({ 
                // use placement from props instead of hard-coding "topRight"
                placement: (_a = notificationConfig === null || notificationConfig === void 0 ? void 0 : notificationConfig.placement) !== null && _a !== void 0 ? _a : DEFAULT_PLACEMENT }, restConfig), { content: (React.createElement(PureContent, { prefixCls: noticePrefixCls, icon: icon, type: type, message: message, description: description, btn: btn, role: role })), className: classNames(type && `${noticePrefixCls}-${type}`, className, notification === null || notification === void 0 ? void 0 : notification.className), style: Object.assign(Object.assign({}, notification === null || notification === void 0 ? void 0 : notification.style), style), closeIcon: realCloseIcon, closable: !!realCloseIcon }));
        };
        // >>> destroy
        const destroy = (key) => {
            var _a, _b;
            if (key !== undefined) {
                (_a = holderRef.current) === null || _a === void 0 ? void 0 : _a.close(key);
            }
            else {
                (_b = holderRef.current) === null || _b === void 0 ? void 0 : _b.destroy();
            }
        };
        const clone = {
            open,
            destroy,
        };
        const keys = ['success', 'info', 'warning', 'error'];
        keys.forEach((type) => {
            clone[type] = (config) => open(Object.assign(Object.assign({}, config), { type }));
        });
        return clone;
    }, []);
    // ============================== Return ===============================
    return [
        wrapAPI,
        React.createElement(Holder, Object.assign({ key: "notification-holder" }, notificationConfig, { ref: holderRef })),
    ];
}
export default function useNotification(notificationConfig) {
    return useInternalNotification(notificationConfig);
}
