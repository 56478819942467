var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/button-has-type */
import React, { Children, createRef, forwardRef, useContext, useEffect, useMemo, useState, } from 'react';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import { composeRef } from 'rc-util/lib/ref';
import warning from '../_util/warning';
import Wave from '../_util/wave';
import { ConfigContext } from '../config-provider';
import DisabledContext from '../config-provider/DisabledContext';
import useSize from '../config-provider/hooks/useSize';
import { useCompactItemContext } from '../space/Compact';
import Group, { GroupSizeContext } from './button-group';
import { isTwoCNChar, isUnBorderedButtonType, spaceChildren } from './buttonHelpers';
import IconWrapper from './IconWrapper';
import LoadingIcon from './LoadingIcon';
import useStyle from './style';
import CompactCmp from './style/compactCmp';
export function convertLegacyProps(type) {
    if (type === 'danger') {
        return { danger: true };
    }
    return { type };
}
function getLoadingConfig(loading) {
    if (typeof loading === 'object' && loading) {
        const delay = loading === null || loading === void 0 ? void 0 : loading.delay;
        const isDelay = !Number.isNaN(delay) && typeof delay === 'number';
        return {
            loading: false,
            delay: isDelay ? delay : 0,
        };
    }
    return {
        loading: !!loading,
        delay: 0,
    };
}
const InternalButton = (props, ref) => {
    var _a, _b;
    const { loading = false, prefixCls: customizePrefixCls, type = 'default', danger, shape = 'default', size: customizeSize, styles, disabled: customDisabled, className, rootClassName, children, icon, ghost = false, block = false, 
    // React does not recognize the `htmlType` prop on a DOM element. Here we pick it out of `rest`.
    htmlType = 'button', classNames: customClassNames, style: customStyle = {} } = props, rest = __rest(props, ["loading", "prefixCls", "type", "danger", "shape", "size", "styles", "disabled", "className", "rootClassName", "children", "icon", "ghost", "block", "htmlType", "classNames", "style"]);
    const { getPrefixCls, autoInsertSpaceInButton, direction, button } = useContext(ConfigContext);
    const prefixCls = getPrefixCls('btn', customizePrefixCls);
    const [wrapSSR, hashId] = useStyle(prefixCls);
    const disabled = useContext(DisabledContext);
    const mergedDisabled = customDisabled !== null && customDisabled !== void 0 ? customDisabled : disabled;
    const groupSize = useContext(GroupSizeContext);
    const loadingOrDelay = useMemo(() => getLoadingConfig(loading), [loading]);
    const [innerLoading, setLoading] = useState(loadingOrDelay.loading);
    const [hasTwoCNChar, setHasTwoCNChar] = useState(false);
    const internalRef = createRef();
    const buttonRef = composeRef(ref, internalRef);
    const needInserted = Children.count(children) === 1 && !icon && !isUnBorderedButtonType(type);
    useEffect(() => {
        let delayTimer = null;
        if (loadingOrDelay.delay > 0) {
            delayTimer = setTimeout(() => {
                delayTimer = null;
                setLoading(true);
            }, loadingOrDelay.delay);
        }
        else {
            setLoading(loadingOrDelay.loading);
        }
        function cleanupTimer() {
            if (delayTimer) {
                clearTimeout(delayTimer);
                delayTimer = null;
            }
        }
        return cleanupTimer;
    }, [loadingOrDelay]);
    useEffect(() => {
        // FIXME: for HOC usage like <FormatMessage />
        if (!buttonRef || !buttonRef.current || autoInsertSpaceInButton === false) {
            return;
        }
        const buttonText = buttonRef.current.textContent;
        if (needInserted && isTwoCNChar(buttonText)) {
            if (!hasTwoCNChar) {
                setHasTwoCNChar(true);
            }
        }
        else if (hasTwoCNChar) {
            setHasTwoCNChar(false);
        }
    }, [buttonRef]);
    const handleClick = (e) => {
        const { onClick } = props;
        // FIXME: https://github.com/ant-design/ant-design/issues/30207
        if (innerLoading || mergedDisabled) {
            e.preventDefault();
            return;
        }
        onClick === null || onClick === void 0 ? void 0 : onClick(e);
    };
    warning(!(typeof icon === 'string' && icon.length > 2), 'Button', `\`icon\` is using ReactNode instead of string naming in v4. Please check \`${icon}\` at https://ant.design/components/icon`);
    warning(!(ghost && isUnBorderedButtonType(type)), 'Button', "`link` or `text` button can't be a `ghost` button.");
    const autoInsertSpace = autoInsertSpaceInButton !== false;
    const { compactSize, compactItemClassnames } = useCompactItemContext(prefixCls, direction);
    const sizeClassNameMap = { large: 'lg', small: 'sm', middle: undefined };
    const sizeFullName = useSize((ctxSize) => { var _a, _b; return (_b = (_a = customizeSize !== null && customizeSize !== void 0 ? customizeSize : compactSize) !== null && _a !== void 0 ? _a : groupSize) !== null && _b !== void 0 ? _b : ctxSize; });
    const sizeCls = sizeFullName ? sizeClassNameMap[sizeFullName] || '' : '';
    const iconType = innerLoading ? 'loading' : icon;
    const linkButtonRestProps = omit(rest, ['navigate']);
    const classes = classNames(prefixCls, hashId, {
        [`${prefixCls}-${shape}`]: shape !== 'default' && shape,
        [`${prefixCls}-${type}`]: type,
        [`${prefixCls}-${sizeCls}`]: sizeCls,
        [`${prefixCls}-icon-only`]: !children && children !== 0 && !!iconType,
        [`${prefixCls}-background-ghost`]: ghost && !isUnBorderedButtonType(type),
        [`${prefixCls}-loading`]: innerLoading,
        [`${prefixCls}-two-chinese-chars`]: hasTwoCNChar && autoInsertSpace && !innerLoading,
        [`${prefixCls}-block`]: block,
        [`${prefixCls}-dangerous`]: !!danger,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, compactItemClassnames, className, rootClassName, button === null || button === void 0 ? void 0 : button.className);
    const fullStyle = Object.assign(Object.assign({}, button === null || button === void 0 ? void 0 : button.style), customStyle);
    const iconClasses = classNames(customClassNames === null || customClassNames === void 0 ? void 0 : customClassNames.icon, (_a = button === null || button === void 0 ? void 0 : button.classNames) === null || _a === void 0 ? void 0 : _a.icon);
    const iconStyle = Object.assign(Object.assign({}, ((styles === null || styles === void 0 ? void 0 : styles.icon) || {})), (((_b = button === null || button === void 0 ? void 0 : button.styles) === null || _b === void 0 ? void 0 : _b.icon) || {}));
    const iconNode = icon && !innerLoading ? (React.createElement(IconWrapper, { prefixCls: prefixCls, className: iconClasses, style: iconStyle }, icon)) : (React.createElement(LoadingIcon, { existIcon: !!icon, prefixCls: prefixCls, loading: !!innerLoading }));
    const kids = children || children === 0 ? spaceChildren(children, needInserted && autoInsertSpace) : null;
    if (linkButtonRestProps.href !== undefined) {
        return wrapSSR(React.createElement("a", Object.assign({}, linkButtonRestProps, { className: classNames(classes, {
                [`${prefixCls}-disabled`]: mergedDisabled,
            }), style: fullStyle, onClick: handleClick, ref: buttonRef }),
            iconNode,
            kids));
    }
    let buttonNode = (React.createElement("button", Object.assign({}, rest, { type: htmlType, className: classes, style: fullStyle, onClick: handleClick, disabled: mergedDisabled, ref: buttonRef }),
        iconNode,
        kids,
        compactItemClassnames && React.createElement(CompactCmp, { key: "compact", prefixCls: prefixCls })));
    if (!isUnBorderedButtonType(type)) {
        buttonNode = (React.createElement(Wave, { component: "Button", disabled: !!innerLoading }, buttonNode));
    }
    return wrapSSR(buttonNode);
};
const Button = forwardRef(InternalButton);
if (process.env.NODE_ENV !== 'production') {
    Button.displayName = 'Button';
}
Button.Group = Group;
Button.__ANT_BUTTON = true;
export default Button;
