var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createTheme } from '@ant-design/cssinjs';
import IconContext from '@ant-design/icons/lib/components/Context';
import useMemo from 'rc-util/lib/hooks/useMemo';
import { merge } from 'rc-util/lib/utils/set';
import * as React from 'react';
import warning from '../_util/warning';
import ValidateMessagesContext from '../form/validateMessagesContext';
import LocaleProvider, { ANT_MARK } from '../locale';
import LocaleContext from '../locale/context';
import defaultLocale from '../locale/en_US';
import { DesignTokenContext } from '../theme/internal';
import defaultSeedToken from '../theme/themes/seed';
import { ConfigConsumer, ConfigContext, defaultIconPrefixCls } from './context';
import { registerTheme } from './cssVariables';
import { DisabledContextProvider } from './DisabledContext';
import useConfig from './hooks/useConfig';
import useTheme from './hooks/useTheme';
import MotionWrapper from './MotionWrapper';
import SizeContext, { SizeContextProvider } from './SizeContext';
import useStyle from './style';
import { defaultTheme } from '../theme/context';
/**
 * Since too many feedback using static method like `Modal.confirm` not getting theme, we record the
 * theme register info here to help developer get warning info.
 */
let existThemeConfig = false;
export const warnContext = process.env.NODE_ENV !== 'production'
    ? (componentName) => {
        warning(!existThemeConfig, componentName, `Static function can not consume context like dynamic theme. Please use 'App' component instead.`);
    }
    : /* istanbul ignore next */
        null;
export { ConfigConsumer, ConfigContext, defaultIconPrefixCls, };
export const configConsumerProps = [
    'getTargetContainer',
    'getPopupContainer',
    'rootPrefixCls',
    'getPrefixCls',
    'renderEmpty',
    'csp',
    'autoInsertSpaceInButton',
    'locale',
    'pageHeader',
];
// These props is used by `useContext` directly in sub component
const PASSED_PROPS = [
    'getTargetContainer',
    'getPopupContainer',
    'renderEmpty',
    'pageHeader',
    'input',
    'pagination',
    'form',
    'select',
    'button',
];
export const defaultPrefixCls = 'ant';
let globalPrefixCls;
let globalIconPrefixCls;
let globalTheme;
function getGlobalPrefixCls() {
    return globalPrefixCls || defaultPrefixCls;
}
function getGlobalIconPrefixCls() {
    return globalIconPrefixCls || defaultIconPrefixCls;
}
function isLegacyTheme(theme) {
    return Object.keys(theme).some((key) => key.endsWith('Color'));
}
const setGlobalConfig = ({ prefixCls, iconPrefixCls, theme, }) => {
    if (prefixCls !== undefined) {
        globalPrefixCls = prefixCls;
    }
    if (iconPrefixCls !== undefined) {
        globalIconPrefixCls = iconPrefixCls;
    }
    if (theme) {
        if (isLegacyTheme(theme)) {
            warning(false, 'ConfigProvider', '`config` of css variable theme is not work in v5. Please use new `theme` config instead.');
            registerTheme(getGlobalPrefixCls(), theme);
        }
        else {
            globalTheme = theme;
        }
    }
};
export const globalConfig = () => ({
    getPrefixCls: (suffixCls, customizePrefixCls) => {
        if (customizePrefixCls) {
            return customizePrefixCls;
        }
        return suffixCls ? `${getGlobalPrefixCls()}-${suffixCls}` : getGlobalPrefixCls();
    },
    getIconPrefixCls: getGlobalIconPrefixCls,
    getRootPrefixCls: () => {
        // If Global prefixCls provided, use this
        if (globalPrefixCls) {
            return globalPrefixCls;
        }
        // Fallback to default prefixCls
        return getGlobalPrefixCls();
    },
    getTheme: () => globalTheme,
});
const ProviderChildren = (props) => {
    const { children, csp: customCsp, autoInsertSpaceInButton, alert, anchor, form, locale, componentSize, direction, space, virtual, dropdownMatchSelectWidth, popupMatchSelectWidth, popupOverflow, legacyLocale, parentContext, iconPrefixCls: customIconPrefixCls, theme, componentDisabled, segmented, statistic, spin, calendar, carousel, cascader, collapse, typography, checkbox, descriptions, divider, drawer, skeleton, steps, image, layout, list, mentions, modal, progress, result, slider, breadcrumb, menu, pagination, input, empty, badge, radio, rate, switch: SWITCH, transfer, avatar, message, tag, table, card, tabs, timeline, timePicker, upload, notification, tree, colorPicker, datePicker, wave, } = props;
    // =================================== Warning ===================================
    if (process.env.NODE_ENV !== 'production') {
        warning(dropdownMatchSelectWidth === undefined, 'ConfigProvider', '`dropdownMatchSelectWidth` is deprecated. Please use `popupMatchSelectWidth` instead.');
    }
    // =================================== Context ===================================
    const getPrefixCls = React.useCallback((suffixCls, customizePrefixCls) => {
        const { prefixCls } = props;
        if (customizePrefixCls) {
            return customizePrefixCls;
        }
        const mergedPrefixCls = prefixCls || parentContext.getPrefixCls('');
        return suffixCls ? `${mergedPrefixCls}-${suffixCls}` : mergedPrefixCls;
    }, [parentContext.getPrefixCls, props.prefixCls]);
    const iconPrefixCls = customIconPrefixCls || parentContext.iconPrefixCls || defaultIconPrefixCls;
    const shouldWrapSSR = iconPrefixCls !== parentContext.iconPrefixCls;
    const csp = customCsp || parentContext.csp;
    const wrapSSR = useStyle(iconPrefixCls, csp);
    const mergedTheme = useTheme(theme, parentContext.theme);
    if (process.env.NODE_ENV !== 'production') {
        existThemeConfig = existThemeConfig || !!mergedTheme;
    }
    const baseConfig = {
        csp,
        autoInsertSpaceInButton,
        alert,
        anchor,
        locale: locale || legacyLocale,
        direction,
        space,
        virtual,
        popupMatchSelectWidth: popupMatchSelectWidth !== null && popupMatchSelectWidth !== void 0 ? popupMatchSelectWidth : dropdownMatchSelectWidth,
        popupOverflow,
        getPrefixCls,
        iconPrefixCls,
        theme: mergedTheme,
        segmented,
        statistic,
        spin,
        calendar,
        carousel,
        cascader,
        collapse,
        typography,
        checkbox,
        descriptions,
        divider,
        drawer,
        skeleton,
        steps,
        image,
        input,
        layout,
        list,
        mentions,
        modal,
        progress,
        result,
        slider,
        breadcrumb,
        menu,
        pagination,
        empty,
        badge,
        radio,
        rate,
        switch: SWITCH,
        transfer,
        avatar,
        message,
        tag,
        table,
        card,
        tabs,
        timeline,
        timePicker,
        upload,
        notification,
        tree,
        colorPicker,
        datePicker,
        wave,
    };
    const config = Object.assign({}, parentContext);
    Object.keys(baseConfig).forEach((key) => {
        if (baseConfig[key] !== undefined) {
            config[key] = baseConfig[key];
        }
    });
    // Pass the props used by `useContext` directly with child component.
    // These props should merged into `config`.
    PASSED_PROPS.forEach((propName) => {
        const propValue = props[propName];
        if (propValue) {
            config[propName] = propValue;
        }
    });
    // https://github.com/ant-design/ant-design/issues/27617
    const memoedConfig = useMemo(() => config, config, (prevConfig, currentConfig) => {
        const prevKeys = Object.keys(prevConfig);
        const currentKeys = Object.keys(currentConfig);
        return (prevKeys.length !== currentKeys.length ||
            prevKeys.some((key) => prevConfig[key] !== currentConfig[key]));
    });
    const memoIconContextValue = React.useMemo(() => ({ prefixCls: iconPrefixCls, csp }), [iconPrefixCls, csp]);
    let childNode = shouldWrapSSR ? wrapSSR(children) : children;
    const validateMessages = React.useMemo(() => {
        var _a, _b, _c, _d;
        return merge(((_a = defaultLocale.Form) === null || _a === void 0 ? void 0 : _a.defaultValidateMessages) || {}, ((_c = (_b = memoedConfig.locale) === null || _b === void 0 ? void 0 : _b.Form) === null || _c === void 0 ? void 0 : _c.defaultValidateMessages) || {}, ((_d = memoedConfig.form) === null || _d === void 0 ? void 0 : _d.validateMessages) || {}, (form === null || form === void 0 ? void 0 : form.validateMessages) || {});
    }, [memoedConfig, form === null || form === void 0 ? void 0 : form.validateMessages]);
    if (Object.keys(validateMessages).length > 0) {
        childNode = (React.createElement(ValidateMessagesContext.Provider, { value: validateMessages }, children));
    }
    if (locale) {
        childNode = (React.createElement(LocaleProvider, { locale: locale, _ANT_MARK__: ANT_MARK }, childNode));
    }
    if (iconPrefixCls || csp) {
        childNode = (React.createElement(IconContext.Provider, { value: memoIconContextValue }, childNode));
    }
    if (componentSize) {
        childNode = React.createElement(SizeContextProvider, { size: componentSize }, childNode);
    }
    // =================================== Motion ===================================
    childNode = React.createElement(MotionWrapper, null, childNode);
    // ================================ Dynamic theme ================================
    const memoTheme = React.useMemo(() => {
        const _a = mergedTheme || {}, { algorithm, token, components } = _a, rest = __rest(_a, ["algorithm", "token", "components"]);
        const themeObj = algorithm && (!Array.isArray(algorithm) || algorithm.length > 0)
            ? createTheme(algorithm)
            : defaultTheme;
        const parsedComponents = {};
        Object.entries(components || {}).forEach(([componentName, componentToken]) => {
            const parsedToken = Object.assign({}, componentToken);
            if ('algorithm' in parsedToken) {
                if (parsedToken.algorithm === true) {
                    parsedToken.theme = themeObj;
                }
                else if (Array.isArray(parsedToken.algorithm) ||
                    typeof parsedToken.algorithm === 'function') {
                    parsedToken.theme = createTheme(parsedToken.algorithm);
                }
                delete parsedToken.algorithm;
            }
            parsedComponents[componentName] = parsedToken;
        });
        return Object.assign(Object.assign({}, rest), { theme: themeObj, token: Object.assign(Object.assign({}, defaultSeedToken), token), components: parsedComponents });
    }, [mergedTheme]);
    if (theme) {
        childNode = (React.createElement(DesignTokenContext.Provider, { value: memoTheme }, childNode));
    }
    // =================================== Render ===================================
    if (componentDisabled !== undefined) {
        childNode = (React.createElement(DisabledContextProvider, { disabled: componentDisabled }, childNode));
    }
    return React.createElement(ConfigContext.Provider, { value: memoedConfig }, childNode);
};
const ConfigProvider = (props) => {
    const context = React.useContext(ConfigContext);
    const antLocale = React.useContext(LocaleContext);
    return React.createElement(ProviderChildren, Object.assign({ parentContext: context, legacyLocale: antLocale }, props));
};
ConfigProvider.ConfigContext = ConfigContext;
ConfigProvider.SizeContext = SizeContext;
ConfigProvider.config = setGlobalConfig;
ConfigProvider.useConfig = useConfig;
Object.defineProperty(ConfigProvider, 'SizeContext', {
    get: () => {
        warning(false, 'ConfigProvider', 'ConfigProvider.SizeContext is deprecated. Please use `ConfigProvider.useConfig().componentSize` instead.');
        return SizeContext;
    },
});
if (process.env.NODE_ENV !== 'production') {
    ConfigProvider.displayName = 'ConfigProvider';
}
export default ConfigProvider;
